<template>
  <!-- Main section start -->
  <div id="home" class="bg-[#090F29] relative">
    <img
      src="../assets/Overlays.png"
      class="absolute inset-0 object-cover object-center w-full h-full bg-repeat-y"
    />
    <section class="min-h-screen main-bg" id="main">
      <div
        class="z-30 flex flex-col items-center justify-center gap-4 p-4 pt-20 md:pt-40 lg:pt-10 xl:pt-14 2xl:pt-32 lg:px-16 2xl:px-56"
      >
        <div class="flex justify-center mt-[76px] sm:mt-[100px]">
          <img
            v-motion-pop-visible
            src="../assets/Dustin-hero.png"
            class="max-h-[130px] w-auto"
            alt="title-text"
          />
        </div>
        <div class="text-white font-['Lato'] font-medium text-3xl sm:text-[36px] mt-10">
          WITH
        </div>

        <!-- Buttons Section -->
        <div class="flex flex-col gap-4 mt-10 md:flex-row">
          <!-- Stake Button -->
          <div
            v-motion-pop-visible
            class="flex flex-col items-center w-full lg:w-[354px] rounded-[20px] border border-[rgba(255,255,255,0.2)] bg-gradient-to-bl from-[rgba(255,255,255,0.1)] to-[rgba(221,221,221,0.2)] backdrop-blur-[50px] p-6"
          >
            <img
              src="../assets/stake-logo.avif"
              class="h-[80px] w-auto"
              alt="Stake Logo"
            />
            <a
              href="#leaderboards"
              @click="redirectTo('#leaderboards')"
              class="skew-x-[-20deg] w-full bg-gradient-to-r from-[#F42F09] mt-6 to-[#FF812E] text-white font-semibold px-6 py-3 flex justify-center"
            >
              <p class="skew-x-[20deg] text-white">VIEW LEADERBOARDS</p>
            </a>
          </div>

          <!-- Empire Drop Button -->
          <div
            v-motion-pop-visible
            class="flex flex-col items-center w-full lg:w-[354px] rounded-[20px] p-6 border border-[rgba(255,255,255,0.2)] bg-gradient-to-bl from-[rgba(255,255,255,0.1)] to-[rgba(221,221,221,0.2)] backdrop-blur-[50px]"
          >
            <img
              src="../assets/empiredrop-hero.avif"
              class="h-[80px] w-auto"
              alt="Empire Drop Logo"
            />
            <a
              href="#leaderboards"
              @click="redirectTo('#leaderboards')"
              class="skew-x-[-20deg] mt-6 w-full bg-gradient-to-r from-[#F42F09] to-[#FF812E] text-white font-semibold px-6 py-3 flex justify-center"
            >
              <p class="skew-x-[20deg] text-white">VIEW LEADERBOARDS</p>
            </a>
          </div>

          <!-- RainGG Button -->
          <div
            v-motion-pop-visible
            class="flex flex-col items-center w-full lg:w-[354px] rounded-[20px] p-6 border border-[rgba(255,255,255,0.2)] bg-gradient-to-bl from-[rgba(255,255,255,0.1)] to-[rgba(221,221,221,0.2)] backdrop-blur-[50px]"
          >
            <img
              src="../assets/raingg-hero.avif"
              class="h-[80px] w-auto"
              alt="Empire Drop Logo"
            />
            <a
              href="#leaderboards"
              @click="redirectTo('#leaderboards')"
              class="skew-x-[-20deg] mt-6 w-full bg-gradient-to-r from-[#F42F09] to-[#FF812E] text-white font-semibold px-6 py-3 flex justify-center"
            >
              <p class="skew-x-[20deg] text-white">VIEW LEADERBOARDS</p>
            </a>
          </div>
        </div>

        <!-- <img
            v-motion-pop-visible
            src="../assets/stake-hero.png"
            class="max-h-[100px] w-auto sm:max-h-[206px]"
            alt="title-text"
          /> -->

        <!-- old buttons -->
        <!-- <div
          v-motion-pop-visible
          class="z-30 flex flex-col items-center justify-center gap-4 mt-5 flex-nowrap sm:flex-row md:mt-6 lg:mt-6"
        >
          <a
            href="https://kick.com/dustin"
            class="skew-x-[-20deg] text-white font-bold border-none w-full px-8 py-[26px] sm:py-6 xl:px-[70px] shadow-2xl bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] hover:border-none"
          >
            <p
              class="skew-x-[20deg] text-white font-extrabold sm:text-lg text-sm text-center whitespace-nowrap"
              style="font-family: Lato, sans-serif"
            >
              WATCH ME LIVE ON KICK
            </p>
          </a>
          <a
            href="https://stake.com/?c=DustinTFP"
            class="skew-x-[-20deg] border-gradient bg-transparent w-full px-9 py-[26px] sm:py-6 xl:px-[78px] border border-solid"
            style="
              border-image: linear-gradient(
                  to right,
                  #f42f09 0%,
                  #e69b6a 50%,
                  #ff812e 100%
                )
                1;
            "
          >
            <p
              class="bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text text-center text-sm skew-x-[20deg] text-white font-extrabold sm:text-lg"
              style="font-family: Lato, sans-serif"
            >
              REGISTER ON STAKE.COM
            </p>
          </a>
        </div> -->
      </div>
      <div class="flex flex-col gap-[115px] mt-[74px] lg:mt-[150px]">
        <img
          src="../assets/slash.png"
          class="hidden object-cover object-center w-full sm:block"
        />
        <img
          src="../assets/slash-sp.png"
          class="block object-cover object-center sm:hidden"
        />

        <div
          v-motion-pop-visible
          class="text-white text-2xl md:text-[48px] text-center font-black"
          style="font-family: Montserrat, sans-serif"
        >
          DUSTIN'S
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
          >
            PROMOTIONS
          </span>
        </div>

        <!-- Promo Card -->
        <div
          class="flex flex-col items-center justify-center w-full px-4 lg:px-0 sm:mt-10 lg:mt-0"
        >
          <div
            class="grid grid-cols-1 lg:grid-cols-2 gap-10 w-full justify-center items-center max-w-[1200px] place-items-center grid-flow-dense"
          >
            <!-- Promo Card 1 -->
            <div
              v-motion-pop-visible
              class="relative flex flex-col items-center justify-center lg:items-start w-[370px] max-w-[600px] h-[370px] sm:w-full sm:h-full pb-20 lg:pb-10 overflow-hidden text-center bg-center bg-no-repeat bg-contain lg:bg-contain bg-promo-bg-sm1 lg:bg-promo-bg1 order-2 lg:order-1"
            >
              <div class="relative z-10 px-6 pt-6 md:pt-4 lg:pt-0 lg:px-4">
                <div
                  class="flex flex-col p-4 mt-6 md:gap-8 w-full lg:w-full lg:h-[340px] lg:gap-0 lg:mt-0"
                >
                  <div
                    class="flex flex-col justify-center mt-4 lg:mt-0 items-center lg:items-start lg:justify-start w-[300px] sm:w-[320px] md:w-[360px] lg:w-[360px] xl:w-[400px] py-4 text-lg text-white font-lato"
                  >
                    <img
                      src="@/assets/empiredrop-logo.png"
                      class="w-[280px] sm:w-[300px] lg:w-[220px]"
                      alt="promo empiredrop"
                    />
                    <h1
                      class="mt-6 leading-8 md:leading-10 lg:leading-8 xl:leading-10 text-white text-center lg:text-start md:text-[26px] lg:text-[30px] xl:text-[24px] font-base"
                    >
                      REGISTER NOW TO GET <br />
                      <span
                        class="lg:text-[24px] xl:text-[32px] font-black bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text"
                        >5 FREE BOXES <span class="text-white">& </span>+10%
                        <span
                          class="lg:text-[24px] xl:text-[32px] font-black bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text"
                          >FIRST DEPOSIT BONUS<span class="text-white">!</span></span
                        >
                      </span>
                    </h1>
                    <h3
                      class="text-center text-white font-base md:text-[24px] lg:text-[20px] xl:text-[24px]"
                    >
                      USE CODE:
                      <span
                        class="text-transparent font-montserrat text-[30px] md:text-[32px] lg:text-[24px] xl:text-[32px] font-black bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                      >
                        DUSTIN
                      </span>
                    </h3>
                  </div>
                  <div
                    class="flex flex-col items-center justify-center gap-4 mt-6 lg:items-start lg:justify-start lg:flex-row"
                  >
                    <a
                      href="https://empiredrop.com/?r=dustin"
                      class="skew-x-[-20deg] w-[280px] lg:w-[300px] xl:w-[340px] flex justify-center items-center text-white font-bold py-6 shadow-2xl bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] hover:border-none"
                    >
                      <span
                        class="skew-x-[20deg] w-full text-white font-extrabold text-base md:text-md"
                      >
                        REGISTER ON EMPIREDROP.COM
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Promo Card 2 -->
            <div
              v-motion-pop-visible
              class="relative flex flex-col items-center justify-center lg:items-start w-[370px] max-w-[600px] h-[370px] sm:w-full sm:h-full pb-20 lg:pb-10 overflow-hidden text-center bg-center bg-no-repeat bg-contain lg:bg-contain bg-promo-bg-sm3 lg:bg-promo-bg3 order-3 lg:order-3 lg:col-span-2"
            >
              <div class="relative z-10 px-6 pt-6 md:pt-4 lg:pt-0 lg:px-4">
                <div
                  class="flex flex-col p-4 mt-6 md:gap-8 w-full lg:w-[500px] lg:h-full lg:gap-0 lg:mt-8"
                >
                  <div
                    class="flex flex-col items-center mt-4 lg:mt-0 w-[300px] sm:w-[320px] lg:w-[360px] xl:w-[400px] justify-center py-4 text-lg text-white lg:items-start font-lato"
                  >
                    <img
                      src="@/assets/raingg-hero.avif"
                      class="w-[180px] sm:w-[180px] md:w-[220px] lg:w-[200px] xl:w-[200px]"
                      alt="promo stake"
                    />
                    <h1
                      class="mt-6 leading-8 md:leading-10 lg:leading-8 xl:leading-10 text-white text-center lg:text-start md:text-[26px] lg:text-[30px] xl:text-[24px] font-base"
                    >
                      REGISTER NOW TO GET <br />
                      <span
                        class="lg:text-[24px] xl:text-[32px] font-black bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text"
                        >3 FREE CASES <span class="text-white">& </span>+5%
                        <span
                          class="lg:text-[24px] xl:text-[32px] font-black bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text"
                          >DEPOSIT BONUS<span class="text-white">!</span></span
                        >
                      </span>
                    </h1>
                    <h3
                      class="text-center text-white font-base lg:text-[20px] xl:text-[24px]"
                    >
                      USE CODE:
                      <span
                        class="text-transparent font-montserrat text-[30px] md:text-[32px] lg:text-[24px] xl:text-[32px] font-black bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                      >
                        DUSTIN
                      </span>
                    </h3>
                  </div>
                  <div
                    class="flex flex-col items-center justify-center gap-4 mt-6 lg:justify-start lg:items-start lg:flex-row"
                  >
                    <a
                      href="https://rain.gg/r/dustintfp"
                      class="skew-x-[-20deg] w-[280px] lg:w-[300px] xl:w-[340px] flex justify-center items-center text-white font-bold py-6 shadow-2xl bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] hover:border-none"
                    >
                      <span
                        class="skew-x-[20deg] w-full text-white font-extrabold text-base md:text-md"
                      >
                        REGISTER ON RAIN.GG
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <!-- Promo Card 3 -->
            <div
              v-motion-pop-visible
              class="relative flex flex-col items-center justify-center lg:items-startw-[370px] max-w-[600px] h-[370px] sm:w-full sm:h-full pb-20 lg:pb-10 overflow-hidden text-center bg-center bg-no-repeat bg-contain lg:bg-contain bg-promo-bg-sm2 lg:bg-promo-bg2 order-1 lg:order-2"
            >
              <div class="relative z-10 px-6 pt-6 md:pt-4 lg:pt-0 lg:px-4">
                <div
                  class="flex flex-col p-4 mt-6 md:gap-8 w-full lg:w-[500px] lg:h-full lg:gap-0 lg:mt-8"
                >
                  <div
                    class="flex flex-col items-center mt-4 lg:mt-0 w-[300px] sm:w-[320px] lg:w-[360px] xl:w-[400px] justify-center py-4 text-lg text-white lg:items-start font-lato"
                  >
                    <img
                      src="@/assets/stake-logo.avif"
                      class="w-[140px] sm:w-[180px] md:w-[220px] lg:w-[100px] xl:w-[120px]"
                      alt="promo stake"
                    />
                    <h1
                      class="mt-6 lg:leading-8 xl:leading-10 text-white text-center lg:text-start lg:text-[20px] xl:text-[24px] font-base"
                    >
                      REGISTER NOW TO GET <br />
                      <span
                        class="lg:text-[24px] xl:text-[32px] font-black bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text"
                        >EXCITING REWARDS EVERYDAY<span class="text-white">!</span>
                      </span>
                    </h1>
                    <h3
                      class="text-center text-white font-base lg:text-[20px] xl:text-[24px]"
                    >
                      USE CODE:
                      <span
                        class="text-transparent font-montserrat text-[30px] md:text-[32px] lg:text-[24px] xl:text-[32px] font-black bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                      >
                        DUSTIN
                      </span>
                    </h3>
                  </div>
                  <div
                    class="flex flex-col items-center justify-center gap-4 mt-6 lg:justify-start lg:items-start lg:flex-row"
                  >
                    <a
                      href="https://stake.com/?c=DustinTFP"
                      class="skew-x-[-20deg] w-[280px] lg:w-[300px] xl:w-[340px] flex justify-center items-center text-white font-bold py-6 shadow-2xl bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] hover:border-none"
                    >
                      <span
                        class="skew-x-[20deg] w-full text-white font-extrabold text-base md:text-md"
                      >
                        REGISTER ON STAKE.COM
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="h-auto">
      <LevelUpReward />
      <LeaderboardSection />
      <SocialSection />
      <PromotionSection />
    </section>
  </div>
</template>

<script>
import LeaderboardSection from "@/sections/LeaderboardSection.vue";
import LevelUpReward from "@/sections/LevelUpReward.vue";
import SocialSection from "@/sections/SocialSection.vue";
import PromotionSection from "@/sections/PromotionSection.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    LeaderboardSection,
    LevelUpReward,
    PromotionSection,
    SocialSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    redirectTo(url) {
      if (url) {
        // Smooth scroll to the #leaderboards section
        if (url === "#leaderboards") {
          document.querySelector(url).scrollIntoView({ behavior: "smooth" });
        } else {
          // If it's another URL, open it in a new tab
          window.open(url, "_blank");
        }
      } else {
        console.warn("URL is missing!");
      }
    },
  },
});
</script>

<style scoped>
body {
  background-color: black;
}

.border-gradient {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #f42f09, #e69b6a, #ff812e) 1;
}
</style>
