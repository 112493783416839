<template>
  <!-- Footer start -->
  <div class="h-full w-full relative pt-12 lg:pt-240 bg-[#090F29]">
    <img
      src="../assets/slash.png"
      class="rotate-[2deg] absolute -top-10 2xl:-top-16 hidden sm:block w-full"
    />
    <img
      src="../assets/slash-sp.png"
      class="rotate-[2deg] absolute -top-10 block sm:hidden w-full"
    />
    <div
      class="flex flex-col justify-start items-center p-6 pb-4 lg:px-10 xl:px-20 max-w-[1400px] mt-[60px] md:mt-[40px] mx-auto"
    >
      <div
        class="flex flex-col items-center justify-between w-full lg:flex-row"
      >
        <!-- Logo Section -->
        <div class="flex flex-row items-center gap-2">
          <a
            @click="
              showMenu = false;
              this.isScrolled = false;
            "
            href=""
          >
            <img
              v-motion-pop-visible
              src="../assets/Dustin-hero.png"
              class="h-[30px] w-auto"
              alt="logo"
            />
          </a>
        </div>

        <!-- Nav Links Section -->
        <div
          v-motion-pop-visible
          class="flex flex-col lg:flex-row text-center items-center justify-center gap-4 sm:gap-[20px] lg:mb-0 text-lg text-white mt-10 lg:mt-0"
          style="font-family: Poppins, sans-serif"
        >
          <a
            href=""
            class="md:px-4 py-1.5 routerBtn text-base text-left font-bold transition-colors rounded-xl"
            >Home</a
          >
          <a
            href="#leaderboard"
            class="md:px-4 py-1.5 routerBtn text-base text-left font-bold transition-colors rounded-xl"
            >Leaderboards</a
          >
          <a
            href="#socials"
            class="md:px-4 py-1.5 routerBtn text-base text-left font-bold transition-colors rounded-xl"
            >Socials</a
          >
          <a
            href="#level-up-rewards"
            class="md:px-4 py-1.5 routerBtn text-base text-left font-bold transition-colors rounded-xl"
            >Rewards</a
          >
        </div>

        <!-- Social Icons Section -->
        <div
          class="flex flex-row items-center justify-center gap-4 mt-10 lg:mt-0 sm:justify-end"
        >
          <a href="https://kick.com/dustin" target="_blank">
            <img src="../assets/kick-footer.png" class="h-[25px] w-[25px]" />
          </a>
          <a href="https://www.instagram.com/dustintfp" target="_blank">
            <img src="../assets/instagram-footer.png" class="h-7 w-7" />
          </a>
          <a href="https://www.youtube.com/@DustinTFP" target="_blank">
            <img src="../assets/youtube-footer.png" class="h-[34px] w-[34px]" />
          </a>
          <a href="https://discord.gg/vds7juyjF8" target="_blank">
            <img
              src="../assets/discord.png"
              class="h-[25px] w-[25px] object-contain"
            />
          </a>
          <a href="https://x.com/dustintfp" target="_blank">
            <img src="../assets/x-footer.png" class="h-[34px] w-[34px]" />
          </a>
        </div>
      </div>

      <div
        class="flex flex-col gap-4 items-start justify-start mt-[75px] mb-[87px] text-base font-normal w-full"
        style="font-family: Lato, sans-serif"
      >
        <p class="text-white">
          If you ever feel that gambling is becoming a problem, we urge you to
          seek help and support. Most people gamble for fun and enjoyment.
          However, some people think of gambling as a way to make money, spend
          more than they can afford, or use gambling to distract themselves from
          everyday problems.
        </p>
        <p class="text-[#BBBBBBBB] font-bold" style="font-family: Oxanium">
          To find further advice, help and support visit:
          <span class="text-inherit">https://www.begambleaware.org/</span>
        </p>
        <p class="text-white">
          We do not take responsibility for misunderstood promotions, losses
          from gambling in casinos or betting on sites which are linked to or
          promoted on this website. Make sure it is legal for you to use each
          site inside your jurisdiction and ALWAYS gamble responsibly!
        </p>
      </div>
    </div>
  </div>
  <!-- Footer end -->
</template>

<script>
export default {};
</script>

<style scoped></style>
