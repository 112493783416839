<template>
  <!-- Leaderboard Section -->
  <section
    id="leaderboards"
    class="h-auto leaderboard-bg mt-24 md:mt-[174px] scroll-mt-20"
  >
    <div
      class="flex items-center justify-center w-full px-4 md:px-8 lg:pt-0"
      id="leaderboard"
    >
      <div
        class="flex flex-col items-center justify-center w-full my-8 md:my-10 md:p-8 rounded-3xl"
      >
        <div
          v-motion-pop-visible
          class="text-white text-2xl font-black md:text-[48px] text-center leading-[48px]"
          style="font-family: Montserrat, sans-serif"
        >
          {{
            currentLeaderboard === "stake"
              ? "$13,000 WEEKLY STAKE"
              : currentLeaderboard === "empire-drop"
              ? "€1750 WEEKLY EMPIRE DROP"
              : "1,500c WEEKLY RAIN.GG"
          }}
          <span
            class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
            >LEADERBOARD</span
          >
        </div>

        <!-- Desktop leaderboard switch -->
        <div v-motion-pop-visible class="flex items-center justify-center mt-32">
          <label
            class="relative flex items-center rounded-full w-[320px] sm:w-[400px] h-[90px] lg:w-[500px] lg:h-[100px] p-1 border border-[rgba(255,255,255,0.2)] bg-gradient-to-bl from-[rgba(255,255,255,0.1)] to-[rgba(221,221,221,0.2)] backdrop-blur-[50px]"
          >
            <!-- Sliding Background -->
            <div
              class="absolute top-1 bottom-1 left-1 h-[calc(100%-8px)] rounded-full transition-all duration-300 ease-in-out"
              :class="{
                'translate-x-[0%]': currentLeaderboard === 'stake',
                'translate-x-[78.3%]': currentLeaderboard === 'empire-drop',
                'translate-x-[224.6%]': currentLeaderboard === 'raingg',
                'bg-gradient-to-bl': true,
                'from-[rgba(255,255,255,0.1)]': true,
                'to-[rgba(221,221,221,0.1)]': true,
                'backdrop-blur-[50px]': true,
              }"
              :style="{
                width:
                  currentLeaderboard === 'stake'
                    ? '30.3%'
                    : currentLeaderboard === 'empire-drop'
                    ? '38.3%'
                    : '30.3%',
              }"
            ></div>

            <!-- Stake Logo Button -->
            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer"
              :class="{
                'bg-transparent': currentLeaderboard !== 'stake',
              }"
              @click="toggleLeaderboard('stake')"
            >
              <img
                src="@/assets/stake-logo.avif"
                alt="Stake"
                class="h-[36px] md:h-[46px] w-auto object-contain"
              />
            </div>

            <!-- EmpireDrop Logo Button -->
            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer"
              :class="{
                'bg-transparent': currentLeaderboard !== 'empire-drop',
              }"
              @click="toggleLeaderboard('empire-drop')"
            >
              <img
                src="@/assets/empiredrop-logo.png"
                alt="EmpireDrop"
                class="h-[36px] md:h-[46px] w-auto object-contain"
              />
            </div>

            <!-- Raingg Logo Button -->
            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer"
              :class="{
                'bg-transparent': currentLeaderboard !== 'raingg',
              }"
              @click="toggleLeaderboard('raingg')"
            >
              <img
                src="@/assets/raingg-logo.avif"
                alt="Raingg"
                class="h-[36px] md:h-[46px] w-auto object-contain"
              />
            </div>
          </label>
        </div>

        <div
          class="flex flex-col items-center h-[300px] md:h-[280px] justify-center my-10 md:my-10 lg:mt-32 lg:mb-5"
        >
          <div class="p-1 bg-transparent rounded-full">
            <div
              v-motion-slide-visible-bottom
              class="bg-transparent"
              style="font-family: Oxanium"
            >
              <!-- Stake Timer -->
              <div
                v-if="currentLeaderboard === 'stake'"
                class="flex flex-row items-center justify-center gap-3"
              >
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      stakeTimer
                        .split(" ")[0]
                        .substring(0, stakeTimer.split(" ")[0].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">DAYS</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      stakeTimer
                        .split(" ")[1]
                        .substring(0, stakeTimer.split(" ")[1].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">HRS</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      stakeTimer
                        .split(" ")[2]
                        .substring(0, stakeTimer.split(" ")[2].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">MIN</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      stakeTimer
                        .split(" ")[3]
                        .substring(0, stakeTimer.split(" ")[3].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">SEC</h6>
                </div>
              </div>

              <!-- Empire Drop Timer -->
              <div
                v-if="empireDropTimer && currentLeaderboard === 'empire-drop'"
                class="flex flex-row items-center justify-center gap-3 mt-5"
              >
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      empireDropTimer
                        .split(" ")[0]
                        .substring(0, empireDropTimer.split(" ")[0].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">DAYS</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      empireDropTimer
                        .split(" ")[1]
                        .substring(0, empireDropTimer.split(" ")[1].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">HRS</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      empireDropTimer
                        .split(" ")[2]
                        .substring(0, empireDropTimer.split(" ")[2].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">MIN</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      empireDropTimer
                        .split(" ")[3]
                        .substring(0, empireDropTimer.split(" ")[3].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">SEC</h6>
                </div>
              </div>

              <!-- Empire Drop Timer -->
              <div
                v-if="rainGGTimer && currentLeaderboard === 'raingg'"
                class="flex flex-row items-center justify-center gap-3 mt-5"
              >
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      rainGGTimer
                        .split(" ")[0]
                        .substring(0, rainGGTimer.split(" ")[0].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">DAYS</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      rainGGTimer
                        .split(" ")[1]
                        .substring(0, rainGGTimer.split(" ")[1].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">HRS</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      rainGGTimer
                        .split(" ")[2]
                        .substring(0, rainGGTimer.split(" ")[2].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">MIN</h6>
                </div>
                <div
                  class="bg-gradient-to-b from-[#F42F09] to-[#FF812E] h-[70px] w-[70px]"
                >
                  <h4 class="text-white text-[36px] leading-[46px] font-bold text-center">
                    {{
                      rainGGTimer
                        .split(" ")[3]
                        .substring(0, rainGGTimer.split(" ")[3].length - 1)
                    }}
                  </h4>
                  <h6 class="text-sm font-bold leading-6 text-center text-white">SEC</h6>
                </div>
              </div>

              <div
                class="mb-20 flex flex-col items-center justify-start pt-14 lg:pt-0 md:mb-0 lg:mb-[125px] md:h-[210px] lg:h-[150px] lg:flex-row lg:items-start lg:justify-center"
              >
                <div v-if="currentLeaderboard === 'empire-drop'" class="block">
                  <label
                    class="flex flex-row items-center justify-center bg-transparent toggle-switch lg:mt-8 lg:flex-row gap-x-2 lg:gap-x-6"
                    style="font-family: Lato, sans-serif"
                  >
                    <!-- Empire-drop: Show both ACTIVE and ENDED -->
                    <div
                      class="relative skew-x-[-20deg] flex h-[62px] w-[160px] items-center justify-center py-6 lg:h-[62px]"
                      :class="
                        previousLeaderboard
                          ? 'border-gradient'
                          : 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] '
                      "
                      @click="
                        previousLeaderboard = false;
                        changeLeaderboard();
                      "
                    >
                      <p
                        class="text-center skew-x-[20deg] text-lg font-extrabold md:leading-[28px]"
                        :class="
                          previousLeaderboard
                            ? 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text'
                            : 'text-white'
                        "
                      >
                        ACTIVE
                      </p>
                    </div>

                    <!-- Empire-drop: Show ENDED only when currentLeaderboard is true -->
                    <div
                      class="relative mt-0 skew-x-[-20deg] flex h-[62px] w-[160px] items-center justify-center py-6 lg:mt-0"
                      :class="
                        previousLeaderboard
                          ? 'flex bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]'
                          : 'text-white border-gradient '
                      "
                      @click="
                        previousLeaderboard = true;
                        changeLeaderboard();
                      "
                    >
                      <p
                        class="text-center skew-x-[20deg] text-lg font-extrabold md:leading-[28px]"
                        :class="
                          previousLeaderboard
                            ? 'text-white'
                            : 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text'
                        "
                      >
                        ENDED
                      </p>
                    </div>
                  </label>
                </div>

                <div v-if="currentLeaderboard === 'raingg'" class="block">
                  <label
                    class="flex flex-row items-center justify-center bg-transparent toggle-switch lg:mt-8 lg:flex-row gap-x-2 lg:gap-x-6"
                    style="font-family: Lato, sans-serif"
                  >
                    <!-- RainGG: Show both ACTIVE and ENDED -->
                    <div
                      class="relative skew-x-[-20deg] flex h-[62px] w-[160px] items-center justify-center py-6 lg:h-[62px]"
                      :class="
                        previousLeaderboard
                          ? 'border-gradient'
                          : 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] '
                      "
                      @click="
                        previousLeaderboard = false;
                        changeLeaderboard();
                      "
                    >
                      <p
                        class="text-center skew-x-[20deg] text-lg font-extrabold md:leading-[28px]"
                        :class="
                          previousLeaderboard
                            ? 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text'
                            : 'text-white'
                        "
                      >
                        ACTIVE
                      </p>
                    </div>

                    <!-- RainGG: Show ENDED only when currentLeaderboard is true -->
                    <!-- <div
                      class="relative mt-0 skew-x-[-20deg] flex h-[62px] w-[160px] items-center justify-center py-6 lg:mt-0"
                      :class="
                        previousLeaderboard
                          ? 'flex bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]'
                          : 'text-white border-gradient '
                      "
                      @click="
                        previousLeaderboard = true;
                        changeLeaderboard();
                      "
                    >
                      <p
                        class="text-center skew-x-[20deg] text-lg font-extrabold md:leading-[28px]"
                        :class="
                          previousLeaderboard
                            ? 'text-white'
                            : 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text'
                        "
                      >
                        ENDED
                      </p>
                    </div> -->
                  </label>
                </div>

                <!-- Stake section: Show only ACTIVE -->
                <div
                  v-if="currentLeaderboard === 'stake' && stakeTimer !== '0d 0h 0m 0s'"
                  class="relative skew-x-[-20deg] flex h-[62px] w-[160px] items-center justify-center bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] py-6 lg:h-[62px] lg:mt-10"
                >
                  <p
                    class="text-center text-white skew-x-[20deg] text-lg font-extrabold md:leading-[28px]"
                  >
                    ACTIVE
                  </p>
                </div>

                <div
                  v-if="currentLeaderboard === 'stake' && stakeTimer == '0d 0h 0m 0s'"
                  class="relative border-gradient mt-0 skew-x-[-20deg] flex h-[62px] w-[160px] items-center justify-center py-6 lg:mt-10"
                >
                  <p
                    class="text-center skew-x-[20deg] text-lg font-extrabold md:leading-[28px]"
                    :class="
                      previousLeaderboard
                        ? 'text-white'
                        : 'bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent whitespace-nowrap bg-clip-text'
                    "
                  >
                    ENDED
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="loading == true"
          class="mt-10 text-xl font-bold text-center text-white md:text-4xl"
          style="font-family: Secular One, sans-serif"
        >
          Loading Leaderboard...
        </div>

        <!-- stake leaderboard -->
        <div
          v-if="loading == false && currentLeaderboard === 'stake'"
          class="relative w-full mt-10"
        >
          <div
            class="flex flex-row justify-center items-center gap-[34px] lg:gap-24 mt-32 lg:mt-0"
          >
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="2nd-banner"
                  />
                  <img
                    src="../assets/crown-silver.png"
                    class="absolute top-0 translate-x-4 -mt-[90px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[1]?.user_name) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      <span class="font-thin text-white">{{ top3[1]?.wagered[0] }}</span
                      >{{ top3.length > 0 ? top3[1]?.wagered.substring(1) : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center lg:-mt-40 w-[150px] absolute top-2 lg:relative"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="1st-banner"
                  />
                  <img
                    src="../assets/crown-gold.png"
                    class="absolute top-0 translate-x-4 -mt-[92px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[0]?.user_name) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat"
                    >
                      <span class="font-thin text-white">{{ top3[0]?.wagered[0] }}</span
                      >{{ top3.length > 0 ? top3[0]?.wagered.substring(1) : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="3rd-banner"
                  />
                  <img
                    src="../assets/crown-bronze.png"
                    class="absolute top-0 translate-x-4 -mt-[90px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[2]?.user_name) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      <span class="font-thin text-white">{{ top3[2]?.wagered[0] }}</span
                      >{{ top3.length > 0 ? top3[2]?.wagered.substring(1) : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8 lg:mt-[91px] mb:mb-24">
            <table
              class="w-full lg:w-auto border-separate border-spacing-y-6 bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] rounded-[20px]"
            >
              <thead
                v-motion-slide-visible-bottom
                class="bg-transparent"
                style="font-family: Montserrat, sans-serif"
              >
                <tr>
                  <th
                    class="text-right pl-4 pr-2 md:px-5 lg:px-[45px] xl:pr-12 xl:pl-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-l"
                  >
                    RANK
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    USER
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    WAGERED
                  </th>
                  <th
                    class="pr-4 pl-1 text-left md:px-5 lg:px-[45px] xl:pl-12 xl:pr-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-r"
                  >
                    PRIZE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-motion-slide-visible-bottom
                  v-for="(item, index) in topRest"
                  :key="index"
                  style="font-family: Lato, sans-serif"
                  class="bg-transparent rounded"
                >
                  <td
                    class="px-2 md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-4 whitespace-nowrap text-base font-normal text-right uppercase"
                    style="font-family: Russo One, system-ui"
                  >
                    <div class="text-white tracking-[4px]">
                      {{ getOrdinalSuffix(index + 4) }}
                    </div>
                  </td>
                  <td
                    class="px-2 md:px-5 max-w-[76px] overflow-hidden text-ellipsis md:max-w-full lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ maskString(item.user_name) }}
                  </td>
                  <td
                    class="px-1 md:px-5 lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ item.wagered }}
                  </td>
                  <td
                    class="px-1 text-left md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-5 whitespace-nowrap text-base text-white font-black tracking-[4px]"
                  >
                    <span class="text-inherit text-[#F42F09]">{{
                      getRewardValue(index + 3)[0]
                    }}</span>
                    {{ getRewardValue(index + 3).substring(1) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- empire drop leaderboard -->
        <div
          v-if="loading == false && currentLeaderboard === 'empire-drop'"
          class="relative w-full mt-10"
        >
          <div
            class="flex flex-row justify-center items-center gap-[34px] lg:gap-24 mt-32 lg:mt-0"
          >
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="2nd-banner"
                  />
                  <img
                    src="../assets/crown-silver.png"
                    class="absolute top-0 translate-x-4 -mt-[90px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 1 ? maskString(top3[1]?.user?.name) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      <span class="font-thin text-white">€</span
                      >{{ top3.length > 0 ? top3[1]?.total_value : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center lg:-mt-40 w-[150px] absolute top-2 lg:relative"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="1st-banner"
                  />
                  <img
                    src="../assets/crown-gold.png"
                    class="absolute top-0 translate-x-4 -mt-[92px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[0]?.user?.name) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat"
                    >
                      <span class="font-thin text-white">€</span
                      >{{ top3.length > 0 ? top3[0]?.total_value : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="3rd-banner"
                  />
                  <img
                    src="../assets/crown-bronze.png"
                    class="absolute top-0 translate-x-4 -mt-[90px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[2]?.user?.name) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      <span class="font-thin text-white">€</span
                      >{{ top3.length > 0 ? top3[2]?.total_value : "" }}
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8 lg:mt-[91px] mb:mb-24">
            <table
              class="w-full lg:w-auto border-separate border-spacing-y-6 bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] rounded-[20px]"
            >
              <thead
                v-motion-slide-visible-bottom
                class="bg-transparent"
                style="font-family: Montserrat, sans-serif"
              >
                <tr>
                  <th
                    class="text-right pl-4 pr-2 md:px-5 lg:px-[45px] xl:pr-12 xl:pl-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-l"
                  >
                    RANK
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    USER
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    WAGERED
                  </th>
                  <th
                    class="pr-4 pl-1 text-left md:px-5 lg:px-[45px] xl:pl-12 xl:pr-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-r"
                  >
                    PRIZE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-motion-slide-visible-bottom
                  v-for="(item, index) in topRest"
                  :key="index"
                  style="font-family: Lato, sans-serif"
                  class="bg-transparent rounded"
                >
                  <td
                    class="px-2 md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-4 whitespace-nowrap text-base font-normal text-right uppercase"
                    style="font-family: Russo One, system-ui"
                  >
                    <div class="text-white tracking-[4px]">
                      {{ getOrdinalSuffix(index + 4) }}
                    </div>
                  </td>
                  <td
                    class="px-2 md:px-5 max-w-[76px] overflow-hidden text-ellipsis md:max-w-full lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ maskString(item.user?.name) }}
                  </td>
                  <td
                    class="px-1 md:px-5 lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    € {{ item.total_value }}
                  </td>
                  <td
                    class="px-1 text-left md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-5 whitespace-nowrap text-base text-white font-black tracking-[4px]"
                  >
                    <span class="text-inherit text-[#F42F09]">{{
                      getRewardValue(index + 3)[0]
                    }}</span>
                    {{ getRewardValue(index + 3).substring(1) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- raingg leaderboard -->
        <div
          v-if="loading == false && currentLeaderboard === 'raingg'"
          class="relative w-full mt-10"
        >
          <div
            class="flex flex-row justify-center items-center gap-[34px] lg:gap-24 mt-32 lg:mt-0"
          >
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="2nd-banner"
                  />
                  <img
                    src="../assets/crown-silver.png"
                    class="absolute top-0 translate-x-4 -mt-[90px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 1 ? maskString(top3[1]?.username) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      {{ top3.length > 0 ? top3[1]?.wagered.toFixed(2) : "" }}
                      <span class="font-thin text-white">c</span>
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(1) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center lg:-mt-40 w-[150px] absolute top-2 lg:relative"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="1st-banner"
                  />
                  <img
                    src="../assets/crown-gold.png"
                    class="absolute top-0 translate-x-4 -mt-[92px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[0]?.username) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat"
                    >
                      {{ top3.length > 0 ? top3[0]?.wagered.toFixed(2) : "" }}
                      <span class="font-thin text-white">c</span>
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-motion-pop-visible
              class="flex flex-col items-center mt-28 lg:mt-[88px]"
            >
              <div
                class="p-2.5 rounded-[10px] w-[150px] lg:w-[300px] bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B]"
              >
                <div
                  class="relative flex flex-col items-center justify-center pt-2 lg:px-6 lg:py-4 rounded-3xl"
                >
                  <img
                    src="../assets/avatar-image-plain.png"
                    class="absolute top-0 translate-x-4 -mt-[52px] lg:-mt-[100px] -ml-10 h-[80px] w-auto lg:h-[150px]"
                    alt="3rd-banner"
                  />
                  <img
                    src="../assets/crown-bronze.png"
                    class="absolute top-0 translate-x-4 -mt-[90px] lg:-mt-[138px] -ml-10"
                    alt="1st-banner"
                  />
                  <div
                    class="flex flex-col justify-center items-center mt-5 lg:mt-8 rounded-[10px]"
                  >
                    <p
                      class="lg:text-[25px] lg:leading-9 text-xs bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Russo One, system-ui"
                    >
                      {{ top3.length > 0 ? maskString(top3[2]?.username) : "" }}
                    </p>
                    <p
                      class="text-white text-lg font-black lg:text-2xl uppercase mt-1 lg:mt-9 overflow-hidden whitespace-nowrap text-ellipsis max-w-[130px] lg:max-w-[264px]"
                      style="font-family: Montserrat, sans-serif"
                    >
                      {{ top3.length > 0 ? top3[2]?.wagered.toFixed(2) : "" }}
                      <span class="font-thin text-white">c</span>
                    </p>
                    <p
                      class="bg-clip-text bg-gradient-to-r text-transparent from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-xs lg:text-sm uppercase"
                      style="font-family: Lato, sans-serif"
                    >
                      Wagered:
                    </p>
                    <div
                      class="mt-1 lg:mt-4 skew-x-[-20deg] relative px-[26px] py-1 lg:py-[21px] lg:px-16 bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
                    >
                      <p
                        class="skew-x-[20deg] text-white font-extrabold text-lg md:text-2xl"
                        style="font-family: Lato, sans-serif"
                      >
                        {{ getRewardValue(2) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8 lg:mt-[91px] mb:mb-24">
            <table
              class="w-full lg:w-auto border-separate border-spacing-y-6 bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] rounded-[20px]"
            >
              <thead
                v-motion-slide-visible-bottom
                class="bg-transparent"
                style="font-family: Montserrat, sans-serif"
              >
                <tr>
                  <th
                    class="text-right pl-4 pr-2 md:px-5 lg:px-[45px] xl:pr-12 xl:pl-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-l"
                  >
                    RANK
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    USER
                  </th>
                  <th
                    class="px-1 md:px-5 lg:px-[45px] xl:px-2 py-2 md:py-4 text-white text-[18px] leading-6 font-bold text-center"
                  >
                    WAGERED
                  </th>
                  <th
                    class="pr-4 pl-1 text-left md:px-5 lg:px-[45px] xl:pl-12 xl:pr-24 py-2 md:py-4 text-white text-[18px] leading-6 font-bold rounded-r"
                  >
                    PRIZE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-motion-slide-visible-bottom
                  v-for="(item, index) in topRest"
                  :key="index"
                  style="font-family: Lato, sans-serif"
                  class="bg-transparent rounded"
                >
                  <td
                    class="px-2 md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-4 whitespace-nowrap text-base font-normal text-right uppercase"
                    style="font-family: Russo One, system-ui"
                  >
                    <div class="text-white tracking-[4px]">
                      {{ getOrdinalSuffix(index + 4) }}
                    </div>
                  </td>
                  <td
                    class="px-2 md:px-5 max-w-[76px] overflow-hidden text-ellipsis md:max-w-full lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-5 lg:px-[45px] xl:px-28 py-2 md:py-5 whitespace-nowrap text-base text-white font-normal text-center tracking-[4px]"
                  >
                    {{ item.wagered.toFixed(2) }} c
                  </td>
                  <td
                    class="px-1 text-left md:px-5 lg:px-[45px] xl:px-12 py-2 md:py-5 whitespace-nowrap text-base text-white font-black tracking-[4px]"
                  >
                    {{ getRewardValue(index + 3).slice(0, -1) }}
                    <span class="text-[#F42F09]">
                      {{ getRewardValue(index + 3).slice(-1) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <PrevLeaderboardSection ref="PrevLeaderboardSection"/> -->
</template>

<script>
// import axios from 'axios';
import axios from "axios";
import moment from "moment-timezone";
// import { watch } from 'vue';
// import PrevLeaderboardSection from './PrevLeaderboardSection.vue';

export default {
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],

      stakeTimer: "" || "0d 0h 0m 0s",
      empireDropTimer: "" || "0d 0h 0m 0s",
      rainGGTimer: "" || "0d 0h 0m 0s",

      stakeLeaderboard: [],
      empireDropLeaderboard: [],
      empireDropPrevLeaderboard: [],
      rainGGLeaderboard: [],
      rainGGPrevLeaderboard: [],

      currentLeaderboard: "stake",
      previousLeaderboard: false,

      stakeStartDate: null,
      stakeEndDate: null,

      empireDropStartDate: null,
      empireDropEndDate: null,
      rainGGStartDate: null,
      rainGGEndDate: null,

      stakeNotFormattedEndDate: null,
      empireDropNotFormattedEndDate: null,
      rainGGNotFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true;

      try {
        // Fetch stake leaderboard data
        const stakeResponse = await axios.get(`${baseUrl}/api/leaderboard/stake`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        });

        this.stakeLeaderboard = stakeResponse.data.leaderboard || [];
        this.stakeNotFormattedEndDate = stakeResponse.data.endDate || null;

        // Fetch empire-drop leaderboard data
        const empireDropResponse = await axios.get(
          `${baseUrl}/api/leaderboard/empire-drop`,
          {
            headers: {
              "x-api-key": process.env.VUE_APP_X_API_KEY,
            },
          }
        );

        const empireDropPrevResponse = await axios.get(
          `${baseUrl}/api/leaderboard/empire-drop?previous=true`,
          { headers: { "x-api-key": process.env.VUE_APP_X_API_KEY } }
        );

        this.startDate = moment(empireDropResponse.data.startDate).format(
          "MMMM DD, YYYY"
        );
        this.endDate = moment(empireDropResponse.data.endDate).format("MMMM DD, YYYY");
        this.empireDropLeaderboard = empireDropResponse.data.leaderboard || [];
        this.empireDropPrevLeaderboard = empireDropPrevResponse.data.leaderboard || [];
        this.empireDropNotFormattedEndDate = empireDropResponse.data.endDate || null;

        const rainGGResponse = await axios.get(`${baseUrl}/api/leaderboard/raingg`, {
          headers: {
            "x-api-key": process.env.VUE_APP_X_API_KEY,
          },
        });

        const rainGGPrevResponse = await axios.get(
          `${baseUrl}/api/leaderboard/raingg?previous=true`,
          { headers: { "x-api-key": process.env.VUE_APP_X_API_KEY } }
        );

        this.startDate = moment(rainGGResponse.data.startDate).format("MMMM DD, YYYY");
        this.endDate = moment(rainGGResponse.data.endDate).format("MMMM DD, YYYY");
        this.rainGGLeaderboard = rainGGResponse.data.leaderboard || [];
        this.rainGGPrevLeaderboard = rainGGPrevResponse.data.leaderboard || [];
        this.rainGGNotFormattedEndDate = rainGGResponse.data.endDate || null;

        // console.log(empireDropResponse.data.endDate, "empire");

        // console.log(empireDropResponse.data.leaderboard);
        // console.log(empireDropResponse.data.endDate);
      } catch (error) {
        console.error("Error loading leaderboard data", error);
      } finally {
        // Ensure loading is set to false even if an error occurs
        this.loading = false;
        this.startStakeTimer();
        this.startEmpireDropTimer();
        this.startRainGGTimer();
        this.changeLeaderboard();
      }
    },

    changeLeaderboard() {
      if (this.currentLeaderboard === "stake") {
        const leaderboard = this.stakeLeaderboard;
        this.top3 = leaderboard.slice(0, 3);
        this.topRest = leaderboard.slice(3, 40);
        this.topForMobile = leaderboard.slice(0, 40);

        // console.log(this.stakeTimer);
        // console.log("Top 3:", this.top3);
        // console.log("Top Rest:", this.topRest);
      } else if (this.currentLeaderboard === "empire-drop") {
        console.log(this.empireDropPrevLeaderboard, "prevs");
        console.log(this.empireDropLeaderboard, "currents");
        if (this.previousLeaderboard) {
          const leaderboard = this.empireDropPrevLeaderboard;
          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 15);
          this.topForMobile = leaderboard.slice(0, 15);
          this.isPrevious = true;
        } else {
          const leaderboard = this.empireDropLeaderboard;
          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 15);
          this.topForMobile = leaderboard.slice(0, 15);
          this.isPrevious = false;
        }

        // console.log("Top 3:", this.top3);
        // console.log("Top Rest:", this.topRest);
      } else if (this.currentLeaderboard === "raingg") {
        if (this.previousLeaderboard) {
          const leaderboard = this.rainGGPrevLeaderboard;
          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 10);
          this.topForMobile = leaderboard.slice(0, 10);
          this.isPrevious = true;
        } else {
          const leaderboard = this.rainGGLeaderboard;
          this.top3 = leaderboard.slice(0, 3);
          this.topRest = leaderboard.slice(3, 10);
          this.topForMobile = leaderboard.slice(0, 10);
          this.isPrevious = false;
        }
      }
    },

    toggleLeaderboard(leaderboardType) {
      if (this.currentLeaderboard !== leaderboardType) {
        this.previousLeaderboard = false; // Reset previousLeaderboard state
      }

      this.currentLeaderboard = leaderboardType;
      this.changeLeaderboard(); // Update leaderboard view
    },

    getOrdinalSuffix(n) {
      const s = ["th", "st", "nd", "rd"],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },

    getRewardValue(rank) {
      let reward = "";

      if (this.currentLeaderboard === "stake") {
        reward = this.getStakeRewardValue(rank); // Get stake reward value for the rank
      } else if (this.currentLeaderboard === "empire-drop") {
        reward = this.getEmpireDropRewardValue(rank, this.previousLeaderboard);
      } else if (this.currentLeaderboard === "raingg") {
        reward = this.getRainGGRewardValue(rank, this.previousLeaderboard);
      }

      // Ensure the result is a string with a dollar sign
      return `${reward}`;
    },

    getStakeRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_STAKE_CURRENT_LEADERBOARD_REWARDS);
      let reward = rank >= prizes.length ? prizes[prizes.length - 1] : prizes[rank];
      return `$${reward}`; // Ensure the reward is returned as a string with a dollar sign
    },

    getEmpireDropRewardValue(rank, isPrevious) {
      let prizes;
      if (isPrevious) {
        prizes = JSON.parse(process.env.VUE_APP_EMPIRE_DROP_PREVIOUS_LEADERBOARD_REWARDS);
      } else {
        prizes = JSON.parse(process.env.VUE_APP_EMPIRE_DROP_CURRENT_LEADERBOARD_REWARDS);
      }

      let reward = rank >= prizes.length ? prizes[prizes.length - 1] : prizes[rank];
      return `€${reward}`; // Ensure the reward is returned as a string with a euro sign
    },

    getRainGGRewardValue(rank, isPrevious) {
      let prizes;
      if (isPrevious) {
        prizes = JSON.parse(process.env.VUE_APP_RAINGG_PREVIOUS_LEADERBOARD_REWARDS);
      } else {
        prizes = JSON.parse(process.env.VUE_APP_RAINGG_CURRENT_LEADERBOARD_REWARDS);
      }

      let reward = rank >= prizes.length ? prizes[prizes.length - 1] : prizes[rank];
      return `${reward}c`; // Ensure the reward is returned as a string with a euro sign
    },

    maskString(str) {
      if (!str || str.length <= 4) return str;
      const start = str.slice(0, 2);
      const end = str.slice(-2);
      const middleAsterisks = "*".repeat(str.length - 4);
      return `${start}${middleAsterisks}${end}`;
    },

    startStakeTimer() {
      const getNextTargetDate = () => {
        const now = moment().utc(); // Use local time for now
        let targetDate = moment(`${this.stakeNotFormattedEndDate}T00:00:00Z`) // Target date in UTC
          .utc()
          .subtract(1, "seconds"); // Subtract 1 second

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "week"); // Add one week if current time is after the target
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment.tz("America/New_York"); // Use EST for now
        const distance = targetDate.diff(nowEst); // Difference from target date
        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.stakeTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`; // Update timer
        } else {
          this.stakeTimer = "0d 0h 0m 0s"; // End condition
          clearInterval(timerInterval); // Stop the timer when it's finished
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000); // Update every second
    },

    startEmpireDropTimer() {
      const getNextTargetDate = () => {
        if (!this.empireDropNotFormattedEndDate) return null;

        const now = moment().utc();
        let targetDate = moment
          .utc(this.empireDropNotFormattedEndDate)
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "week");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      if (!targetDate) return;

      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment().tz("America/New_York");
        const distance = targetDate.diff(nowEst);

        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.empireDropTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.empireDropTimer = "0d 0h 0m 0s";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },

    startRainGGTimer() {
      const getNextTargetDate = () => {
        if (!this.rainGGNotFormattedEndDate) return null;

        const now = moment().utc();
        let targetDate = moment
          .utc(this.rainGGNotFormattedEndDate)
          .subtract(1, "seconds");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "week");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      if (!targetDate) return;

      let timerInterval;

      const updateTimer = () => {
        const nowEst = moment().tz("America/New_York");
        const distance = targetDate.diff(nowEst);

        if (distance >= 0) {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.rainGGTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        } else {
          this.rainGGTimer = "0d 0h 0m 0s";
          clearInterval(timerInterval);
        }
      };

      updateTimer();
      timerInterval = setInterval(updateTimer, 1000);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.border-gradient {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #f42f09, #e69b6a, #ff812e) 1;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 632px;
  height: 72px;
  border: 2px #2cceee;
  border-radius: 20px;
  padding: 0 8px;
  cursor: pointer;

  @media screen and (max-width: 640px) {
    width: 300px;
    height: 74px;
  }
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .left-label,
.toggle-switch .right-label {
  transition: background-color 0.2s ease, border 0.12s ease;
}
</style>
