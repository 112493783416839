<template>
  <!-- Navbar start -->
  <nav
    :class="{
      'bg-gradient-to-b from-[rgba(44,_52,_117,_1)] to-[rgba(44,_52,_117,_0)] shadow-md transition-opacity':
        isScrolled || showMenu,
      'bg-gradient-to-b from-[rgba(44,_52,_117,_1)] to-[rgba(44,_52,_117,_0)]':
        !isScrolled && !showMenu,
    }"
    class="w-full fixed py-3.5 px-5 md:px-5 lg:px-20 xl:px-40 flex justify-between items-center z-40 transition-all h-20"
  >
    <a
      @click="
        showMenu = false;
        this.isScrolled = false;
      "
      href=""
    >
      <div class="flex flex-row items-center justify-start h-full gap-2">
        <img
          v-motion-pop-visible
          src="../assets/Dustin-hero.png"
          class="h-[30px] w-auto"
          alt="logo"
        />
      </div>
    </a>

    <div class="lg:hidden">
      <button @click="toggleMenu" class="focus:outline-none">
        <svg
          v-if="!showMenu"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          class="w-9 h-9"
        >
          <path
            fill-rule="evenodd"
            d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="white"
          class="w-9 h-9"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>

    <!-- Centered Nav Links -->
    <div class="justify-center flex-1 hidden lg:flex">
      <div
        v-motion-pop-visible
        class="flex flex-col gap-4 text-lg text-white uppercase md:flex-row whitespace-nowrap md:items-center"
        style="font-family: Poppins, sans-serif"
      >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#home"
          class="px-4 py-1.5 text-lg transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Home</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#leaderboard"
          class="px-4 py-1.5 text-lg transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Leaderboards</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#socials"
          class="px-4 py-1.5 text-lg transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Socials</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#level-up-rewards"
          class="px-4 py-1.5 text-lg transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Rewards</a
        >
      </div>
    </div>

    <!-- Mobile Side Bar -->
    <div
      :class="{
        'bg-[#090F29] translate-x-0': isMobile() && showMenu,
        'bg-opacity-80': !isScrolled && !showMenu,
        '-translate-x-full': !showMenu && isMobile(),
        flex: showMenu || !isMobile(),
      }"
      class="lg:bg-transparent lg:hidden w-[80%] -z-10 top-0 h-screen md:h-auto absolute left-0 right-0 mt-20 md:mt-[80px] transform duration-300 ease-in-out lg:mt-2 shadow flex-col gap-4 justify-start lg:justify-end items-start lg:item-center px-0 lg:flex-row lg:static lg:shadow-none lg:translate-y-0 md:transition-all self-center"
      role="menu"
      :aria-expanded="showMenu.toString()"
    >
      <div
        class="flex flex-col md:gap-[20px] lg:mb-0 lg:flex-row justify-evenly uppercase whitespace-nowrap text-lg text-white w-full md:w-auto"
        style="font-family: Poppins, sans-serif"
      >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#home"
          class="px-4 md:py-1.5 py-[26px] text-lg font-extrabold transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Home</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#leaderboards"
          class="px-4 md:py-1.5 py-[26px] text-lg font-extrabold transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Leaderboards</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#socials"
          class="px-4 md:py-1.5 py-[26px] text-lg font-extrabold transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Socials</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#level-up-rewards"
          class="px-4 md:py-1.5 py-[26px] text-lg font-extrabold transition-colors md:rounded-xl"
          :class="isMobile() ? 'routerBtnMobile' : 'routerBtn'"
          >Rewards</a
        >
        <a
          @click="
            showMenu = false;
            this.isScrolled = false;
          "
          href="#level-up-rewards"
          class="relative py-3 xl:px-10 skew-x-[-20deg] ml-6 mt-6 w-[240px] border-gradient transition-all duration-300 ease-in-out bg-transparent text-center overflow-hidden group"
        >
          <!-- Border Gradient -->
          <div
            class="absolute inset-0 border-2 rounded-sm pointer-events-none"
            style="
              border-image: linear-gradient(
                to right,
                #f42f09,
                #e69b6a,
                #ff812e
              );
              border-image-slice: 1;
            "
          ></div>

          <!-- Hover Effect -->
          <div
            class="absolute inset-0 opacity-0 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
          ></div>

          <!-- Login Text -->
          <p
            class="relative bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text font-extrabold text-xs sm:text-sm skew-x-[20deg] transition-colors duration-300 ease-in-out group-hover:text-white"
            style="font-family: Lato, sans-serif"
          >
            LOGIN
          </p>
        </a>
      </div>
    </div>

    <!-- Login Button -->
    <div v-motion-pop-visible class="z-30 items-center hidden lg:flex">
      <a
        href="https://stake.com/?c=DustinTFP"
        class="relative px-6 py-3 xl:px-10 skew-x-[-20deg] border-gradient transition-all duration-300 ease-in-out bg-transparent text-center overflow-hidden group"
      >
        <div
          class="absolute inset-0 border-2 rounded-sm pointer-events-none"
          style="
            border-image: linear-gradient(to right, #f42f09, #e69b6a, #ff812e);
            border-image-slice: 1;
          "
        ></div>
        <div
          class="absolute inset-0 opacity-0 transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E]"
        ></div>
        <p
          class="relative bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-transparent bg-clip-text font-extrabold text-xs sm:text-sm skew-x-[20deg] transition-colors duration-300 ease-in-out group-hover:text-white"
          style="font-family: Lato, sans-serif"
        >
          LOGIN
        </p>
      </a>
    </div>
  </nav>
  <!-- Navbar end -->
</template>

<script>
export default {
  data() {
    return {
      isScrolled: false,
      isLogout: false,
      showMenu: false,
    };
  },
  methods: {
    navigateAndCloseMenu(route) {
      this.showMenu = false;
      this.$router.push(route);
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;

      if (this.showMenu && window.innerWidth < 768) {
        this.isScrolled = true;
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu && window.innerWidth < 768) {
        this.isScrolled = true;
      } else if (!this.showMenu && window.scrollY === 0) {
        this.isScrolled = false;
      }
    },
    isMobile() {
      return window.innerWidth < 1024; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.border-gradient {
  border-width: 0px;
  border-style: solid;
  border-image: linear-gradient(to right, #f42f09, #e69b6a, #ff812e) 1;
}
</style>
