<template>
  <section
    class="flex flex-col mt-10 xl:mt-56 pb-[109px] xl:pb-[237px] justify-center items-center pt-12 md:pt-16 lg:pt-20"
    id="socials"
  >
    <div class="px-4 py-8">
      <div
        v-motion-pop-visible
        class="text-white text-2xl md:text-[48px] font-black leading-[48px] text-center"
        style="font-family: Montserrat, sans-serif"
      >
        DUSTIN'S
        <span
          class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
        >
          PROMOTIONS
        </span>
      </div>
    </div>
    <div
      class="flex flex-col lg:flex-row justify-center gap-[84px] items-center md:items-start sm:gap-[74px] sm:gap-y-32 mt-[98px] sm:mt-[190px] px-4 flex-wrap xl:px-0"
    >
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col rounded-[10px] justify-center h-fit w-fit items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] pb-3 px-6 md:px-9 md:pb-8"
      >
        <img
          src="../assets/promo-calendar.avif"
          class="w-[150px] h-[150px] md:h-[232px] md:w-[232px] -mt-20 md:-mt-28"
          alt="calendar"
        />
        <div
          class="flex flex-col items-center justify-center"
          style="font-family: Lato, sans-serif"
        >
          <h2
            class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] text-lg md:text-[36px] md:leading-[54px] font-[900] text-center"
          >
            3:00 PM GMT
          </h2>
          <h5 class="text-white text-xs md:text-base font-[900px]">
            STREAMING ALL DAYS
          </h5>
          <div
            class="mt-4 text-lg text-white md:text-2xl md:mt-8"
            style="font-family: Montserrat, sans-serif"
          >
            FRIDAY
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
              >BONUS</span
            >
          </div>
          <h3 class="text-lg text-white md:text-2xl">GIVEAWAYS</h3>
        </div>
      </div>
      <!-- <div
        v-motion-slide-visible-bottom
        class="flex flex-col justify-center h-fit w-fit items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] pb-3 px-3 md:px-9 md:pb-8"
      >
        <img
          src="../assets/promo-coins.png"
          class="w-[150px] h-[150px] md:h-[232px] md:w-[232px] -mt-20 md:-mt-28"
          alt="coins"
        />
        <div
          class="flex flex-col items-center justify-center"
          style="font-family: Lato, sans-serif"
        >
          <a href="#" class="skew-x-[-20deg] text-lg md:text-lg font-[800] text-center bg-transparent border-2 border-solid py-2 md:py-3 px-5"
            style=" border-image: linear-gradient(to right, #00579F 0%, #8BC3CE 50%, #2CCEEE 100%) 1">
            <p class="skew-x-[20deg] text-white">SUPPORT</p>
          </a>
          <h5 class="text-white text-xs leading-5 md:text-base font-[900px]">SHOW YOUR SUPPORT</h5>
          <div class="mt-3 text-lg leading-6 text-white md:text-2xl md:mt-8" style="font-family: Montserrat, sans-serif">
            YOU CAN
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
              >DONATE</span
            >
          </div>
          <h3 class="text-lg text-white md:text-2xl">
            AND
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
              >SUPPORT</span
            >
            ME
          </h3>
        </div>
      </div> -->
      <div
        v-motion-slide-visible-bottom
        class="flex flex-col justify-center h-fit w-fit rounded-[10px] items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] pb-3 px-3 md:px-9 md:pb-8"
      >
        <img
          src="../assets/promo-discord.avif"
          class="w-[150px] h-[150px] md:h-[232px] md:w-[232px] -mt-20 md:-mt-28"
          alt="discord"
        />
        <div
          class="flex flex-col items-center justify-center font-sans"
          style="font-family: Lato, sans-serif"
        >
          <a
            href="https://discord.gg/vds7juyjF8"
            target="_blank"
            class="skew-x-[-20deg] text-lg md:text-lg font-[800] border-gradient md:mt-1 text-center bg-transparent border-2 border-solid py-2 md:py-3 px-5"
            style="
              border-image: linear-gradient(
                  to right,
                  #f42f09 0%,
                  #e69b6a 50%,
                  #ff812e 100%
                )
                1;
            "
          >
            <p class="skew-x-[20deg] text-white">JOIN NOW</p>
          </a>
          <h5 class="text-white text-base font-[900px]">JOIN NOW AND ENJOY</h5>
          <div
            class="mt-3 text-lg leading-6 text-white md:text-2xl md:mt-8"
            style="font-family: Montserrat, sans-serif"
          >
            JOIN THE
            <span
              class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
            >
              BONUS
            </span>
          </div>
          <h3 class="text-lg text-white md:text-2xl">GIVEAWAYS</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.border-gradient {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #f42f09, #e69b6a, #ff812e) 1;
}
</style>
