<template>
  <section
    id="socials"
    class="flex flex-col items-center justify-center pt-12 md:pt-16 lg:pt-20"
  >
    <div class="p-2 md:py-8 md:px-4">
      <div
        v-motion-pop-visible
        class="text-white text-2xl md:text-[48px] font-black text-center leading-[48px]"
        style="font-family: Montserrat, sans-serif"
      >
        FOLLOW ME ON MY
        <span
          class="text-transparent bg-clip-text bg-gradient-to-r from-[#F42F09] via-[#E69B6A] to-[#FF812E] font-[900]"
        >
          SOCIALS
        </span>
      </div>
    </div>
    <div
      class="flex flex-wrap flex-row justify-center gap-6 md:gap-[74px] lg:gap-[40px] mt-6 md:mt-[84px]"
    >
      <a
        v-motion-slide-visible-bottom
        href="https://x.com/dustintfp"
        target="_blank"
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <img src="../assets/X.png" class="w-12 h-12" alt="X" />
      </a>
      <a
        v-motion-slide-visible-bottom
        href="https://www.instagram.com/dustintfp"
        target="_blank"
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <img src="../assets/instagram.png" class="w-12 h-12" alt="instagram" />
      </a>
      <a
        v-motion-slide-visible-bottom
        href="https://www.youtube.com/@DustinTFP"
        target="_blank"
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <img src="../assets/youtube.png" class="w-12 h-12" alt="youtube" />
      </a>
      <a
        v-motion-slide-visible-bottom
        href="https://kick.com/dustin"
        target="_blank"
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <img src="../assets/kick.png" class="w-12 h-12" alt="kick" />
      </a>
      <a
        v-motion-slide-visible-bottom
        href="https://discord.gg/vds7juyjF8"
        target="_blank"
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <img
          src="../assets/discord.png"
          class="object-contain w-12 h-12"
          alt="discord"
        />
      </a>
      <a
        v-motion-slide-visible-bottom
        href="https://www.tiktok.com/@dustintfp"
        target="_blank"
        class="flex flex-col justify-center items-center bg-gradient-to-r from-[#FFFFFF15] to-[#D9D9D90B] border border-[#FFFFFF33] h-[150px] w-[150px]"
      >
        <img src="../assets/tiktok.png" class="w-12 h-12" alt="tiktok" />
      </a>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.border-gradient {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #f42f09, #e69b6a, #ff812e) 1;
}
</style>
